<div class="container container-courselist">
  <div
    style="
      max-height: 10%;
      flex: 1 1 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
    "
  >
    <section-header title="Mis Comprobantes"></section-header>
  </div>

  <mat-card>
    <mat-card-content>
      <table mat-table [dataSource]="dataTableSource" style="width: 100%">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>N° Comprobante</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="course_name">
          <th mat-header-cell *matHeaderCellDef>Curso</th>
          <td mat-cell *matCellDef="let element">{{ element.course_name }}</td>
        </ng-container>

        <ng-container matColumnDef="status_semaphore">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element">
            <!-- <mat-icon [style.color]="element[column.name].value">circle</mat-icon> -->
            <mat-chip-list>
              <mat-chip
                class="button-state"
                [style.backgroundColor]="element.status_semaphore.value"
                selected
              >
                {{ element.status_semaphore.label }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="display: flex; flex-direction: row; box-sizing: border-box"
            >
              <button
                *ngIf="element.image.split('.').pop() != 'pdf'"
                title="Ver Comprobante"
                mat-flat-button
                class="details-button"
                color="primary"
                (click)="openReceiptDialog(element.id)"
                style="margin-right: 10px"
              >
                <mat-icon>receipt</mat-icon>
              </button>
              <a
                *ngIf="element.image.split('.').pop() == 'pdf'"
                title="Abrir PDF"
                [href]="element.image"
                target="_blank"
                color="primary"
                mat-flat-button
              >
                <mat-icon>open_in_new</mat-icon>
              </a>
              <button
                title="Eliminar"
                mat-flat-button
                class="delete-button"
                *ngIf="element.id_status == null"
                color="warn"
                (click)="delete(element.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999" style="text-align: center">
            No se encontraron datos.
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100, 1000]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
