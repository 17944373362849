import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  userId: number = 0;
  personId: number = 0;

  constructor(private identity: IdentityService) {
  }

  ngOnInit(): void {
    this.userId = this.identity.getDecodedToken().id_user;
    this.personId = this.identity.getDecodedToken().id_person;
  }

}
