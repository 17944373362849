<div class="container container-credentiallist-header">
  <div
    style="
      max-height: 10%;
      flex: 1 1 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
    "
  >
    <section-header
      title="Credenciales"
      [showNewButton]="true"
      [newButtonText]="'Nueva Persona'"
      (newButtonPressed)="new()"
    >
    </section-header>
  </div>

  <div class="credentials-table-container">
    <mat-card *ngIf="credentialTypeList.length">
      <mat-tab-group animationDuration="0ms">
        <mat-tab label="Legajos">
          <credential-list-table
            [credentialType]="'all'"
          ></credential-list-table>
        </mat-tab>
        <div *ngFor="let credentialType of credentialTypeList">
          <mat-tab label="{{ credentialType.name }}">
            <credential-list-table
              [credentialType]="credentialType.code"
            ></credential-list-table>
          </mat-tab>
        </div>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
