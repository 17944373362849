<div class="container" style="height: auto;">
  <div style="width: 70%; margin-left: auto; margin-right: auto;">
    <h1>Agregar comprobante</h1>
    <mat-card>
      <form [formGroup]="receiptForm" (ngSubmit)="sendReceipt()">
        <mat-card-content>
          <div>
            <div *ngIf="!isStudent" >
              <div style="margin-bottom: 10px">
                <mat-checkbox
                  color="primary"
                  (change)="handleCashChange($event)"
                  >Pago en Efectivo</mat-checkbox>
                <input matInput [hidden]="true" />
              </div>
            </div>
              
            <file-button *ngIf="!isCash" id="fileButton" color="primary" text="Agregar archivo" icon="add"
              (fileChanged)="onFileChanged($event)"></file-button>
            <span class="form-custom-error" *ngIf="receiptForm.get('image')?.hasError('required') && (receiptForm.get('image')?.dirty || receiptForm.get('image')?.touched)">
              No se agregó ningún archivo.
            </span>
            <span class="form-custom-error" *ngIf="!receiptFileValid">
              El archivo agregado no tiene el formato correcto. Formatos permitidos: jpg, jpeg, png, pdf.
            </span>
            <div class='field-with-card'>
              <mat-card class="closed-enrollments-alert">
                <mat-icon>warning</mat-icon> No se aceptarán pagos parciales de la cuota o matricula.  Los pagos parciales serán rechazados.
              </mat-card>
              <payment-fees [form]="receiptForm" [courseId]="courseId" [personId]="personId" [created]='isStudent'></payment-fees>
            </div>
            <span class="form-custom-error"
              *ngIf="receiptForm.get('fees')?.hasError('required') && (receiptForm.get('fees')?.dirty || receiptForm.get('fees')?.touched)">
              Este campo es <strong>obligatorio.</strong>
            </span>

            <mat-form-field appearance="outline">
              <mat-label>Fecha del pago</mat-label>
              <input matInput type="date" formControlName="transfer_date">
              <mat-error *ngIf="receiptForm.get('transfer_date')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" style="display: flex;">
              <mat-label>Nombre del titular de la cta. Bancaria</mat-label>
              <input matInput type="text" formControlName="transfer_owner">
              <mat-error *ngIf="receiptForm.get('transfer_owner')?.hasError('required')">
                Este campo es <strong>obligatorio.</strong>
              </mat-error>
            </mat-form-field>

            <div style="display: flex; place-content: stretch flex-end;">
              <span>{{receiptForm.get('comments')?.value.length}} / 300</span>
            </div>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Observaciones</mat-label>
              <textarea matInput formControlName="comments" rows="5"></textarea>

              <mat-error *ngIf="receiptForm.get('comments')?.hasError('maxlength')">
                Máximo 300 caracteres.
              </mat-error>
            </mat-form-field>

            <div style="display: flex; place-content: stretch space-between;">
              <button mat-flat-button type="button" (click)="goToPreviousPage()">
                <mat-icon>chevron_left</mat-icon>
                Volver
              </button>
              <button mat-flat-button type="submit" color="primary">
                Cargar
                <mat-icon>file_upload</mat-icon>
              </button>

            </div>
          </div>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
