<div class="container container-list">
  <div style="
      max-height: 10%;
      flex: 1 1 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
    ">
    <div style="display: flex; flex-direction: row; box-sizing: border-box;">
      <h1 style="align-self: center; flex: 1 1 0%; box-sizing: border-box;">Mis Solicitudes</h1>
      <p-splitButton label="Nueva Solicitud" icon="pi pi-plus" size='small' [model]="items" #actionMenu
        (onClick)="showMenuOptions(actionMenu, $event)" appendTo="body" />
    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <p-tabView>


        <!-- PENDING REQUESTS -->
        <p-tabPanel header="Pendientes">
          <p-dataView #dv [value]="allPendingRequests">
            <ng-template pTemplate="list" let-requests>
              <requests-list-table [requests]="requests" (onClick)="showFlowRequestDialog($event)" />
            </ng-template>
          </p-dataView>

        </p-tabPanel>


        <!-- FINISHED REQUESTS -->
        <p-tabPanel header="Finalizadas">
          <p-dataView #dv [value]="allFinishRequests">
            <ng-template pTemplate="list" let-requests>
              <requests-list-table [requests]="requests" (onClick)="showFlowRequestDialog($event)" />
            </ng-template>
          </p-dataView>
        </p-tabPanel>


        <!-- ALL REQUESTS -->
        <p-tabPanel header="Todas">
          <p-dataView #dv [value]="allRequestsWithoutFilter()">
            <ng-template pTemplate="list" let-requests>
              <requests-list-table [requests]="requests" (onClick)="showFlowRequestDialog($event)" />
            </ng-template>
          </p-dataView>
        </p-tabPanel>

      </p-tabView>
    </mat-card-content>
  </mat-card>

  <flow-request-dialog *ngIf="flowRequestContent" [keyNodeActive]="flowRequestContent.last_node"
    [nodeData]="flowLicence" (onClose)="flowRequestContent = null" />
</div>