import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CustomTableColumnType } from './enums/custom-table-column-type.enum';
import { CustomTableColumn } from './interfaces/custom-table-column';
import { CustomTableGenericButton } from './interfaces/custom-table-generic-button';
import { CustomTableStructure } from './interfaces/custom-table-structure';

@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() dataSource: CustomTableStructure;
  @Input() showActions: boolean = false;
  @Input() showEditButton: boolean = false;
  @Input() showDeleteButton: boolean = false;
  @Input() showDetailsButton: boolean = false;
  @Input() showGenericButton1: boolean = false;
  @Input() showGenericButton2: boolean = false;
  @Input() showGenericButton3: boolean = false;
  @Input() genericButton1: CustomTableGenericButton;
  @Input() genericButton1Title: string;
  @Input() genericButton2: CustomTableGenericButton;
  @Input() genericButton2Title: string;
  @Input() genericButton3: CustomTableGenericButton;
  @Input() genericButton3Title: string;
  @Input() paginatorPageSize: number = 10;
  @Input() filterable: boolean = false;
  @Input() sortable: boolean = false;
  @Input() pageIndex: number = 0;

  @Output() editButtonPressed: EventEmitter<number> = new EventEmitter();
  @Output() deleteButtonPressed: EventEmitter<number> = new EventEmitter();
  @Output() detailsButtonPressed: EventEmitter<number> = new EventEmitter();
  @Output() genericButton1Pressed: EventEmitter<number> = new EventEmitter();
  @Output() genericButton2Pressed: EventEmitter<any> = new EventEmitter();
  @Output() genericButton3Pressed: EventEmitter<any> = new EventEmitter();
  @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter();

  displayedColumns: string[] = [];
  filterExpression: string = '';
  rowsCount: number = 0;
  data: MatTableDataSource<any>;

  columnTypes: typeof CustomTableColumnType = CustomTableColumnType;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.paginatorPageSize;
    // this.data.paginator = this.paginator;
  }

  ngOnChanges(): void {
    this.displayedColumns = [];
    this.dataSource.columns.forEach(column => this.displayedColumns.push(column.name));
    if (this.showActions) this.displayedColumns.push('actions');
    // debugger
    this.completeTable()
  }

  completeTable() {
    if (this.filterExpression) {
      const tableFiltered = this.dataSource.data?.filter(
        item => {
          // item is a dict, chech if any of the values contains the filter expression
          for (const key in item) {
            if (item[key]?.toString().toLowerCase().includes(this.filterExpression)) {
              return true;
            }
          }
          return false;
        }
      ) ?? [];
      this.paginatedTable(tableFiltered)
    } else {
      this.paginatedTable(this.dataSource.data ?? [])
    }
  }

  paginatedTable(dataTable: any[]) {
    this.rowsCount = dataTable.length;
    if (this.paginator){
      const start = this.paginator.pageIndex * this.paginator.pageSize;
      const end = start + this.paginator.pageSize;
      this.data = new MatTableDataSource<any>(dataTable?.slice(start, end) || []);
      // this.data.paginator = this.paginator;
    } else {
      const start = 0 * this.paginatorPageSize;
      const end = start + this.paginatorPageSize;
      this.data = new MatTableDataSource<any>(dataTable?.slice(start, end) || []);
      // this.data.paginator = this.paginator;
    }
  }

  getColumnsByType(type: CustomTableColumnType) {
    return this.dataSource.columns.filter(c => c.type == type);
  }

  onClickEditButton(itemId: number) {
    this.editButtonPressed.emit(itemId);
  }

  onClickDeleteButton(itemId: number) {
    this.deleteButtonPressed.emit(itemId);
  }

  onClickDetailsButton(itemId: number) {
    this.detailsButtonPressed.emit(itemId);
  }

  onClickGenericButton1(itemId: number) {
    this.genericButton1Pressed.emit(itemId);
  }

  onClickGenericButton2(element: any) {
    this.genericButton2Pressed.emit(element);
  }
  
  onClickGenericButton3(element: any) {
    this.genericButton3Pressed.emit(element);
  }

  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterExpression = filterValue.trim().toLowerCase();
    this.paginator.firstPage()
    this.completeTable()
  }

  pageChanged(event: PageEvent) {
    this.pageChange.emit(event);
    this.completeTable()
  }

}
