import { Component, OnInit, HostListener } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Credential } from "src/app/models/credential";
import { IdentityService } from "src/app/services/identity.service";
import { ApiCredentialsService } from "src/app/services/api-credentials.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";
import { Person } from "src/app/models/person";
import { ApiPersonsService } from "src/app/services/api-persons.service";

@Component({
  selector: "app-user-credential",
  templateUrl: "./user-credential.component.html",
  styleUrls: ["./user-credential.component.scss"],
})
export class UserCredentialComponent implements OnInit {
  personData: Person;
  credentials: Credential[] = [];
  personId: number;
  isPortrait: boolean;

  constructor(
    private identity: IdentityService,
    private APICredentials: ApiCredentialsService,
    private APIPersons: ApiPersonsService,
    private ui: UserInterfaceService
  ) {
    this.ui.spin$.next(true);
  }

  async ngOnInit(): Promise<void> {
    this.checkOrientation();
    this.personId = this.identity.getDecodedToken().id_person;
    this.getPerson();
    await this.getAllCredentials();
    this.ui.spin$.next(false);

  }

  getPerson() {
    this.APIPersons.getById(this.personId).subscribe((response) => {
      this.personData = response.data;
    });
  }

async getAllCredentials() {
    try {
      //get all Credentials instance from api
      this.credentials = await firstValueFrom(this.APICredentials.getAll(this.personId)).then((response) => {
        return response.data;
      
      });
    } catch (error) {
      console.error(error);
    }
  }

  checkOrientation() {
    this.isPortrait = window.innerHeight > window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkOrientation();
  }
}
