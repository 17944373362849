import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { windowCount } from 'rxjs';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, url: string, printable?: boolean }) { }

  ngOnInit(): void {
  }

  print() {
    var url = this.data.url;
    var w = window.open('', '');
    w!.document.write('<html><head>');
    w!.document.write('</head><body onload="window.print();" >');
    w!.document.write('<img id="print-image-element" src="' + url + '"/>');
    w!.document.write('<script>var img = document.getElementById("print-image-element"); img.addEventListener("load",function(){ window.document.close(); window.close(); }); </script>');
    w!.document.write('</body></html>');
    w!.window.print();
  }

}
