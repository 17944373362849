import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'lottie-component',
  templateUrl: "./lottie.component.html",
  standalone: true,
  styleUrls: ['./lottie.component.scss'],
  imports: [LottieComponent],

})

export class CustomLottieComponent {
  @Input() lottiePath: string = '/assets/lotties/fab_logo_animated.json';
  options: AnimationOptions = {
    path: this.lottiePath,
  };
  

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
  anAnimationItem: AnimationItem;

}
