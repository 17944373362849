import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ApiRecordsService } from "src/app/services/api-records.service";
import { ApiPersonsService } from "src/app/services/api-persons.service";
import { UtilsService } from "src/app/services/utils.service";

import { RecordDialogComponent } from "src/app/components/record-dialog/record-dialog.component";
import { CustomTableStructure } from "src/app/components/custom-table/interfaces/custom-table-structure";
import { CustomTableColumn } from "src/app/components/custom-table/interfaces/custom-table-column";
import { CustomTableOptions } from "src/app/components/custom-table/interfaces/custom-table-options";
import { CustomTableColumnType } from "src/app/components/custom-table/enums/custom-table-column-type.enum";
import { CustomTableGenericButton } from "src/app/components/custom-table/interfaces/custom-table-generic-button";

import { Record } from "src/app/models/record";
import { Person } from "src/app/models/person";


import {
  MatLegacyDialog as MatDialog,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: "record-list-table",
  templateUrl: "./record-list-table.component.html",
  styleUrls: ["./record-list-table.component.scss"],
})
export class RecordListTableComponent {
  today: Date = new Date();
  @Input() recordType: string = "";
  @Input() label: string = "Records";

  allPersonListCols: CustomTableColumn[] = [
    { name: "id_person", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "firstname", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "lastname", text: "Apellido", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
  ];

  recordListCols: CustomTableColumn[] = [
    { name: "id", text: "ID", type: CustomTableColumnType.TEXT },
    { name: "id_person", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "firstname", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "lastname", text: "Apellido", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
    
    
  ];
  createRecordButton: CustomTableGenericButton = {
    icon: "assignment_ind",
    text: "Ver",
    color: "primary",
    title: "Ver Record",
  };

  allPersonListTableOptions: CustomTableOptions = {
    filtering: true,
    sorting: false,
  };

  recordListTableOptions: CustomTableOptions = {
    filtering: true,
    sorting: false,
  };

  allPersonListTableStructure: CustomTableStructure = {
    columns: this.allPersonListCols,
    data: [],
    options: this.allPersonListTableOptions,
  };

  recordListTableStructure: CustomTableStructure = {
    columns: this.recordListCols,
    data: [],
    options: this.recordListTableOptions,
  };

  selected_id_person: number = 0;
  selected_identity_number: string = "";

  constructor(
    private utils: UtilsService,
    private APIRecords: ApiRecordsService,
    private APIPersons: ApiPersonsService,
    private recordDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getAllPersonData();
    this.getAllRecordsWithPersonData(this.recordType);
    this.recordListTableStructure.data = [
      {
        id: 1,
        id_person: 1,
        firstname: "Juan",
        lastname: "Perez",
        identity_number: "12345678",
      },
      {
        id: 2,
        id_person: 2,
        firstname: "Maria",
        lastname: "Gomez",
        identity_number: "87654321",
      }
    ];
  }

  getAllPersonData() {
    this.APIRecords.getAllWithPersonData("all").subscribe(
      (response) => {

        this.allPersonListTableStructure.data = response.data;
        
      }
    );
  }

  getAllRecordsWithPersonData(recordType: string) {
    this.APIRecords.getAllWithPersonData(recordType).subscribe((response) => {
      console.log(`getAllWithPersonData[${recordType}]: `, response.data);
      this.recordListTableStructure.data = response.data;

      // this.recordListTableStructure.data = response.data.map(
      //   (record: Record) => {
      //     return {
      //       ...record,
      //       bout_status: this.utils.getBoutResultSemaphore(record.bout_result.result, record.bout_result.method),
      //     };
      //   }
      // );
    });
  }

  openEditRecordDialog(record: Record) {
    console.log("Edit Record", record);
  
  }

  delete(record: Record) {
    console.log("Delete Record", record);
  }

  openCreateRecordDialog(id_person: number) {
    //get person data
    let person: Person;
    this.APIPersons.getById(id_person).subscribe((response) => {
      person = response.data;
      console.log("Person", person);
      const dialogRef = this.recordDialog.open(RecordDialogComponent, {
        width: "90%",
        maxWidth: "800px",
        data: {
          title: `Crear Record para: ${person.firstname} ${person.lastname}`,
          personId: person.id,
        },
      });
    });
  }

}
