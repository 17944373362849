<h1 mat-dialog-title>{{data.name}}</h1>
<div mat-dialog-content>
  <div>
    <div style="display: flex; flex-wrap: wrap;">
      <div style="flex: 1; flex-basis: 50%;">
        <strong>Fecha de inicio:</strong><br>
        {{data.start_date | date: 'dd/MM/yyyy'}}
      </div>
      <div style="flex: 1; flex-basis: 50%;">
        <strong>Fecha de Fin:</strong><br>
        {{data.end_date | date: 'dd/MM/yyyy'}}
      </div>
      <div style="flex: 1; flex-basis: 50%;">
        <strong>Días y Horarios:</strong><br>
        {{data.day_time}}
      </div>
      <div style="flex: 1; flex-basis: 50%;">
        <strong>Precio total:</strong><br>
        ${{data.total_price}}<br>
        (Matrícula: ${{data.enrollment_price}} + {{data.fees}} cuotas de ${{data.fee_price}})
      </div>
    </div>

    <div>
      <div>
        <strong>Descripción del curso:</strong><br>
        {{data.description}}
      </div>
    </div>
    
    <br />
    <mat-divider></mat-divider>

    <div>
      <div>
        <h3>CÓMO INSCRIBIRTE</h3>
        <p>Para poder inscribirte a este curso, primero debés abonar la matrícula del mismo, de la siguiente manera:
        </p>
        <p>1 - En la sección MEDIOS DE PAGO te informamos los medios de pago aceptados hasta el momento.</p>
        <p>2 - Una vez que hayas abonado por alguno de esos medios, volvé a esta pantalla y presioná "QUIERO
          INSCRIBIRME".</p>
        <p>3 - En la siguiente pantalla encontrarás un formulario donde debés cargar la captura/foto de tu comprobante
          de pago y
          seleccionar a qué concepto de pago corresponde (Matrícula o Nro. de cuota).</p>
        <p>4 - Una vez que hayas enviado el comprobante, tendrás que esperar la aprobación del mismo. No te preocupes,
          te va a
          llegar un email.</p>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cerrar</button>
  <button mat-flat-button mat-dialog-close color="primary" (click)="goToAddPayment()">Quiero Inscribirme</button>
</div>
