import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ElementsAlertDialog } from 'src/app/models/elements-alert-dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  confirm: boolean = false;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public elements: ElementsAlertDialog) { }

  ngOnInit(): void {
  }

  onClickOk(): void {
    this.confirm = true;
    this.dialogRef.close(this.confirm);
  }

  onClickCancel(): void {
    this.confirm = false;
    this.dialogRef.close();
  }

}
