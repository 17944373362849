import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfilesEnum } from 'src/app/enums/user-profiles-enum';
import { City } from 'src/app/models/city';
import { Nationality } from 'src/app/models/nationality';
import { RegistrationRequest } from 'src/app/models/registration-request';
import { State } from 'src/app/models/state';
import { ApiCitiesService } from 'src/app/services/api-cities.service';
import { ApiNationalitiesService } from 'src/app/services/api-nationalities.service';
import { ApiStatesService } from 'src/app/services/api-states.service';
import { ApiUsersService } from 'src/app/services/api-users.service';
import { ApiChangeProfileRequestsService } from 'src/app/services/api-change-profile-requests.service';
import { IdentityService } from 'src/app/services/identity.service';
import { UserInterfaceService } from 'src/app/services/user-interface.service';
import { AES, enc, pad } from "crypto-js";
import { UtilsService } from 'src/app/services/utils.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: "user-profile-form",
  templateUrl: "./user-profile-form.component.html",
  styleUrls: ["./user-profile-form.component.scss"],
})
export class UserProfileFormComponent implements OnInit {
  @Input() userId: number = 0;
  @Input() personId: number = 0;

  regForm: UntypedFormGroup;
  encrypt: Record<string, any> = {};

  initialData: any;
  showLoading: boolean = false;
  receiptFileValid: boolean = true;
  active: boolean = false;
  allowEditData: boolean = true;
  passwordValidator: Array<any> = [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)];

  states: State[] = [];
  cities: City[] = [];
  citiesByState: City[] = [];
  nationalities: Nationality[] = [];

  showPassword: boolean = true;
  passwordVisible: boolean = true;
  allowEditButton: boolean = true;

  userPhoto: string;
  userIdCardFront: string;
  userIdCardBack: string;

  profileStatusMessage: {
    icon: string,
    content: string,
    status: string
  } = {
    icon: "notification_important",
    content: "Los siguientes datos son información básica de registro. Para editarlos, se requerirá una revisión y aprobación por parte de la FAB.",
    status: "danger"
  };

  constructor(
    private fb: UntypedFormBuilder,
    private identity: IdentityService,
    private APIStates: ApiStatesService,
    private APICities: ApiCitiesService,
    private APINationalities: ApiNationalitiesService,
    private alertDialog: MatDialog,
    private APIUsers: ApiUsersService,
    private ApiChangeProfileRequests: ApiChangeProfileRequestsService,
    private ui: UserInterfaceService,
    private utils: UtilsService,
  ) {
    this.regForm = this.fb.group({
      id_user: [0, Validators.required],
      id_person: [0, Validators.required],
      id_profile: [UserProfilesEnum.USER, Validators.required],
      firstname: [{value: null, disabled: true}, Validators.required],
      lastname: [{value: null, disabled: true}, Validators.required],
      id_nationality: [null, Validators.required],
      identity_number: [
        {value: null, disabled: true},
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]\d*$/),
        ]),
      ],
      birthdate: [null, Validators.required],
      active: null,
      address: [null, Validators.required],
      postal_code: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      id_state: [null, Validators.required],
      id_city: [null, Validators.required],
      gender: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      email: [null, [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      password: [null, this.passwordValidator],
      photo: [null, Validators.required],
      identity_card_front: [null, Validators.required],
      identity_card_back: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.encrypt["key"] = enc.Hex.parse("ecb4bf74b9d399ba25f737c7529f2437");
    this.encrypt["iv"] = enc.Hex.parse("eb2daf7ba35c59225faafc3752227d94");

    this.ui.spin$.next(true);
    this.APIStates.getAll().subscribe((response) => {
      this.states = response.data;

      this.APICities.getAll().subscribe((response) => {
        this.cities = response.data;
      });

      this.APINationalities.getAll().subscribe((response) => {
        this.nationalities = response.data;

        this.APIUsers.getUserPerson(this.userId, this.personId).subscribe(
          (response) => {
            this.regForm.get("password")?.setValidators(this.passwordValidator.slice(1));
            this.regForm.get("password")?.updateValueAndValidity()
            response.data.active = response.data.active === "1";
            this.regForm.patchValue(response.data);
            this.initialData = response.data;

            this.initialData.photo = this.initialData.photo.split("/").pop();
            this.initialData.identity_card_front = this.initialData.identity_card_front.split("/").pop();
            this.initialData.identity_card_back = this.initialData.identity_card_back.split("/").pop();

            if (response.data.modification_status === "pending") {
              this.profileStatusMessage = {
                icon: "access_time",
                content: "Su solicitud de cambio de datos se encuentra pendiente de aprobación...",
                status: "warning"
              };
              this.allowEditButton = false;
            }

            // ASIGNO LAS IMAGENES A LAS VARIABLES Y LIMPIO LOS PARAMETROS DEL FORM
            this.userPhoto = this.regForm.get("photo")?.value;
            this.userIdCardFront = this.regForm.get(
              "identity_card_front"
            )?.value;
            this.userIdCardBack =
              this.regForm.get("identity_card_back")?.value;

            this.regForm.get("photo")?.setValue(null);
            this.regForm.get("identity_card_front")?.setValue(null);
            this.regForm.get("identity_card_back")?.setValue(null);

            this.onStateChange(this.regForm.get("id_state")!.value);
            this.ui.spin$.next(false);
          }
        );
      });
    });

    // SI EL USUARIO ESTA LOGUEADO ES PORQUE UN USUARIO ESCUELA ESTA DANDO DE ALTA EL ALUMNO
    if (this.identity.tokenExists()) {
      // this.showPassword = false;
      this.passwordVisible = true;
      this.regForm.get("photo")?.clearValidators();
      this.regForm.get("identity_card_front")?.clearValidators();
      this.regForm.get("identity_card_back")?.clearValidators();
    }
  }

  onFileChanged(field: string, event: any) {
    this.receiptFileValid = this.utils.receiptFileValidation(event.type);
    this.regForm.get(field)?.setValue(event);
  }

  onStateChange(event: any) {
    this.citiesByState = this.cities.filter(
      (c) => c.id_state.toString() == event
    );
  }

  toggleEditData() {
    this.allowEditData = !this.allowEditData;
    this.regForm.get('identity_number')?.enable();
    this.regForm.get('firstname')?.enable();
    this.regForm.get('lastname')?.enable();
  }

  updateSensitiveData() {
    const modifiedValues = Object.entries({
      firstname: this.regForm.get('firstname')?.value,
      lastname: this.regForm.get('lastname')?.value,
      identity_number: this.regForm.get('identity_number')?.value,
      photo: this.regForm.get("photo")?.value,
      identity_card_front: this.regForm.get("identity_card_front")?.value,
      identity_card_back: this.regForm.get("identity_card_back")?.value,
    }).reduce((a, [k, v]) => (v && v !== this.initialData[k]) ? {...a, [k]: v} : a, {});

    const originalValues = Object.keys(modifiedValues).reduce((result: any, key: string) => {
      result[key] = this.initialData[key];
      return result;
    }, {});

    if (Object.keys(modifiedValues).length === 0) {
      this.allowEditData = !this.allowEditData;
      this.regForm.get('identity_number')?.disable();
      this.regForm.get('firstname')?.disable();
      this.regForm.get('lastname')?.disable();

      this.regForm.get('identity_number')?.setValue(this.initialData.identity_number);
      this.regForm.get('firstname')?.setValue(this.initialData.firstname);
      this.regForm.get('lastname')?.setValue(this.initialData.lastname);
      this.regForm.get("photo")?.setValue(null);
      this.regForm.get("identity_card_front")?.setValue(null);
      this.regForm.get("identity_card_back")?.setValue(null);
      return;
    }
    const dialogRef = this.alertDialog.open(AlertDialogComponent, {
      data: {
        title: "Solicitud de cambios",
        content: `¿Está seguro que desea solicitar la modificación de sus datos de registro?`,
        status: "warning",
        okButtonLabel: "Confirmar",
        closeButtonLabel: "Cancelar"
      }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      // this.allowEditData = !this.allowEditData;
      this.allowEditData = false;
      this.regForm.get('identity_number')?.disable();
      this.regForm.get('firstname')?.disable();
      this.regForm.get('lastname')?.disable();
      if (!confirm) {
        this.regForm.get('identity_number')?.setValue(this.initialData.identity_number);
        this.regForm.get('firstname')?.setValue(this.initialData.firstname);
        this.regForm.get('lastname')?.setValue(this.initialData.lastname);
        this.regForm.get("photo")?.setValue(null);
        this.regForm.get("identity_card_front")?.setValue(null);
        this.regForm.get("identity_card_back")?.setValue(null);
        return;
      }
      this.ui.spin$.next(true);

      this.ApiChangeProfileRequests.createNewChangeRequest(this.personId, originalValues, modifiedValues).subscribe(response => {
        this.ui.spin$.next(false);
        this.allowEditData = !this.allowEditData;
        if (response.status !== 200) {
          alert("Ocurrio un error al solicitar el cambio de datos");
          return;
        }
        this.profileStatusMessage = {
          icon: "access_time",
          content: "Su solicitud de cambio de datos se encuentra pendiente de aprobación...",
          status: "warning"
        }
        alert("Solicitud enviada correctamente");
        this.allowEditButton = false;
      });
    });

  }

  register(): void {
    if (this.regForm.valid && this.receiptFileValid) {
      this.ui.spin$.next(true);

      this.regForm.get("photo")?.setValue(null);
      this.regForm.get("identity_card_front")?.setValue(null);
      this.regForm.get("identity_card_back")?.setValue(null);
      let regRequest: RegistrationRequest = this.regForm.getRawValue();

      regRequest["password"] = AES
        .encrypt(regRequest["password"], this.encrypt["key"], {
          iv: this.encrypt["iv"],
          padding: pad.ZeroPadding,
        })
        .toString();
      
      regRequest = {
        ...regRequest,
        firstname: this.initialData.firstname,
        lastname: this.initialData.lastname,
        identity_number: this.initialData.identity_number
      };

      this.APIUsers.register(regRequest).subscribe((response) => {
        alert("Usuario modificado correctamente");
        this.APIUsers.getUserPerson(this.userId, this.personId).subscribe(
          (response) => {
            this.regForm.get("password")?.setValidators(this.passwordValidator.slice(1));
            this.regForm.get("password")?.updateValueAndValidity()
            response.data.active = response.data.active === "1";
            this.regForm.patchValue(response.data);
            this.initialData = response.data;

            this.initialData.photo = this.initialData.photo.split("/").pop();
            this.initialData.identity_card_front = this.initialData.identity_card_front.split("/").pop();
            this.initialData.identity_card_back = this.initialData.identity_card_back.split("/").pop();

            if (response.data.modification_status === "pending") {
              this.profileStatusMessage = {
                icon: "access_time",
                content: "Su solicitud de cambio de datos se encuentra pendiente de aprobación...",
                status: "warning"
              };
              this.allowEditButton = false;
            }

            // ASIGNO LAS IMAGENES A LAS VARIABLES Y LIMPIO LOS PARAMETROS DEL FORM
            this.userPhoto = this.regForm.get("photo")?.value;
            this.userIdCardFront = this.regForm.get(
              "identity_card_front"
            )?.value;
            this.userIdCardBack =
              this.regForm.get("identity_card_back")?.value;

            this.regForm.get("photo")?.setValue(null);
            this.regForm.get("identity_card_front")?.setValue(null);
            this.regForm.get("identity_card_back")?.setValue(null);

            this.onStateChange(this.regForm.get("id_state")!.value);
            this.ui.spin$.next(false);
          }
        );
      });
    } else {
      Object.keys(this.regForm.controls).forEach(field => {
        const control = this.regForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
