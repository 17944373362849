<div class="chart-container">
  <h1>{{ chartTitle }}</h1>
  <div *ngIf="!chartType">
    <p>Chart type not defined</p>
  </div>
  <div *ngIf="chartType === 'line'">
    <p-chart type="line" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
  <div *ngIf="chartType === 'bar'">
    <p-chart type="bar" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
  <div *ngIf="chartType === 'radar'">
    <p-chart type="radar" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
  <div *ngIf="chartType === 'doughnut'">
    <p-chart type="doughnut" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
  <div *ngIf="chartType === 'polarArea'">
    <p-chart type="polarArea" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>

  <div *ngIf="chartType === 'bubble'">
    <p-chart type="bubble" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
  <div *ngIf="chartType === 'scatter'">
    <p-chart type="scatter" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
  <div *ngIf="chartType === 'pie'">
    <p-chart type="pie" [data]="dataToRender" [options]="chartOptions">
    </p-chart>
  </div>
</div>
