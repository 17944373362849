<div class="container container-payment">
  <div style="max-width: 70%; flex: 1 1 100%; box-sizing: border-box;">
    <h1>Contactanos</h1>
    <mat-card>
        <mat-card-content>
          <p>Contactanos</p>
          <ul>
            <li><b>Email escuela:</b> Escuela&#64;faboxeo.com.ar</li>
          </ul>
        </mat-card-content>
    </mat-card>
  </div>
</div>
