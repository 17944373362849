import { Component, Input, OnInit } from "@angular/core";
import { License } from "src/app/models/license";
import { Person } from "src/app/models/person";
import QRCode from "qrcode"
import { CustomLottieComponent } from "../lottie/lottie.component";

@Component({
  selector: "license-horizontal",
  templateUrl: "./license-horizontal.component.html",
  styleUrls: ["./license-horizontal.component.scss"],
})
export class LicenseHorizontalComponent implements OnInit {
  @Input() license: License;
  @Input() person: Person;
  @Input() onlyView: boolean = false;

  userData: any = {};
  seccionateBackground: boolean = false;
  licenseType: string;
  licenseData: any = {};
  qrImage: string = "assets/baseQR.webp";
  licenseStatus: string = "not-configured";
  institutionLogo = CustomLottieComponent;

  ngOnInit(): void {
    if (this.license?.status) {
      this.licenseStatus = this.license.status;
    }
    this.licenseType = {
      valid: "Valida",
      expired: "Vencida",
      disabled: "Inhabilitada",
      "not-configured": "No configurada",
    }[this.licenseStatus] || "No configurada"

    this.userData = {
      personId: this.license?.id_person || " - ",
      location: `${this.person?.state_name || " - "}, ${this.person?.city_name || " - "}`,
      fullName: `${this.person?.firstname || " - "} ${this.person?.lastname || " - "}`,
      identityNumber: Number(this.person?.identity_number).toLocaleString(),
      gender: this.person?.gender,
      photo: this.person?.photo,
      birthdate: this.person?.birthdate,
      state_name: this.person?.state_name,
      city_name: this.person?.city_name,
    };
    
    this.licenseData = {
      status: this.licenseStatus,
      type: this.license?.type,
      valid_until: this.license?.valid_until,
    };
  }

  formatExpiration(date: string) {
    if (!date) return " - ";
    const dateObject = new Date(date + "T00:00:00");
    const month = dateObject.toLocaleString("default", { month: "long" });
    const year = dateObject.getFullYear();

    return `${dateObject.getDate()} de ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
  }
  
  ngOnChanges(): void {
    this.ngOnInit();
  }  
}