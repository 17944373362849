import { Injectable } from '@angular/core';
import { CoursePaymentsStatus } from '../models/course-payments-status';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { HttpBaseResponse } from '../models/base-response';
import { AddPaymentRequest } from '../models/payment-requests';
import { ReceiptStatusRequest } from '../models/receipt-status-request';

@Injectable({
  providedIn: 'root'
})
export class ApiPaymentsService extends ApiBaseService {

  constructor(private http: HttpClient) {
    super();
    this._controller = "payments";
  }

  /**
    * @ngdoc function
    * @name getPendingFees
    * @param courseId number
    * @methodOf ApiPaymentsService
    * @description Se obtienen los pagos realizados y sus montos pendientes (si hubiese).
    * @return HttpBaseResponse
    */

  getPendingFees(courseId: number, personId: number, noSelect: boolean = false) {
    return this.http.get<HttpBaseResponse>(`${this._apiUrl}/${this._controller}/pending_fees/${courseId}/${personId}/${noSelect? true : ''}`);
  }

  /**
    * @ngdoc function
    * @name getReceiptDetails
    * @param receiptId number
    * @methodOf ApiPaymentsService
    * @description Se obtienen los detalles del comprobante.
    * @return HttpBaseResponse
    */

  getReceiptDetails(receiptId: number) {
    return this.http.get<HttpBaseResponse>(`${this._apiUrl}/${this._controller}/receipt_details/${receiptId}`);
  }

  /**
    * @ngdoc function
    * @name getReceiptsByStatus
    * @param statusId number
    * @methodOf ApiPaymentsService
    * @description Se obtienen los comprobantes segun estado enviado.
    * @return HttpBaseResponse
    */

  getReceiptsByStatus(statusId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/receipts_by_status`;
    if (statusId) endpoint += `/${statusId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
    * @ngdoc function
    * @name getReceiptStatusValues
    * @methodOf ApiPaymentsService
    * @description Se obtienen los valores de status posibles para el comprobante.
    * @return HttpBaseResponse
    */

  getReceiptStatusValues() {
    return this.http.get<HttpBaseResponse>(`${this._apiUrl}/${this._controller}/receipt_status_values`);
  }

  /**
    * @ngdoc function
    * @name getFeesHistory
    * @methodOf ApiPaymentsService
    * @description Se obtiene el historial de pagos de un curso y un usuario.
    * @return HttpBaseResponse
    */

  getPaymentsHistory(courseId: number, personId: number) {
    return this.http.get<HttpBaseResponse>(`${this._apiUrl}/${this._controller}/payments_history/${courseId}/${personId}`);
  }

  /**
    * @ngdoc function
    * @name getReceiptsByPerson
    * @methodOf ApiPaymentsService
    * @description Se obtiene listado de comprobantes de un usuario.
    * @return HttpBaseResponse
    */

  getReceiptsByPerson(personId: number) {
    return this.http.get<HttpBaseResponse>(`${this._apiUrl}/${this._controller}/person_receipts/${personId}`);
  }

  /**
    * @ngdoc function
    * @name addPayment
    * @param request AddPaymentRequest
    * @methodOf ApiPaymentsService
    * @description Agrega un nuevo comprobante.
    * @return HttpBaseResponse
    */

  addPayment(request: AddPaymentRequest) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/add`, request);
  }

  /**
    * @ngdoc function
    * @name setReceiptStatus
    * @param request ReceiptStatusRequest
    * @methodOf ApiPaymentsService
    * @description Se aprueba/rechaza un comprobante.
    * @return HttpBaseResponse
    */

  setReceiptStatus(request: ReceiptStatusRequest) {
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/set_receipt_status`, request);
  }

  /**
    * @ngdoc function
    * @name deleteReceipt
    * @param receiptId number
    * @methodOf ApiPaymentsService
    * @description Elimina un comprobante y todas sus relaciones de pago.
    * @return HttpBaseResponse
    */

  deleteReceipt(receiptId: number) {
    let data = { id: receiptId }
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/delete_receipt`, data);
  }

  /**
    * @ngdoc function
    * @name reverseReceiptStatus
    * @param receiptId number
    * @methodOf ApiPaymentsService
    * @description Vuelve hacia atras el estado del comprobante informado por tesorería.
    * @return HttpBaseResponse
    */

  reverseReceiptStatus(receiptId: number) {
    let data = { id_receipt: receiptId };
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/reverse_receipt_status`, data);
  }


}
