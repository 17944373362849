<div class="card">
  <ng-container *ngIf="isPortrait">
    <p-carousel [value]="credentials" [numVisible]="1" [numScroll]="1"
      orientation="vertical" verticalViewPortHeight="700px" [circular]="true">
      <ng-template let-credential pTemplate="credential">
        <div class="credential-item">
          <div class="credential-item-content mb-3">
            <credential-vertical *ngIf='personData && credentials' [credential]="credential" [person]='personData'
              [onlyView]='false'>
            </credential-vertical>
            </div>
            </div>
            </ng-template>
            </p-carousel>
            </ng-container>
            
            <ng-container *ngIf="!isPortrait">
              <p-carousel [value]="credentials" [numVisible]="1" [numScroll]="1" orientation="horizontal" [circular]="true">
                <ng-template let-credential pTemplate="credential">
                  <div class="credential-item">
                    <div class="credential-item-content mb-3">
                      <credential-horizontal *ngIf='personData && credentials' [credential]="credential" [person]='personData'
                        [onlyView]='false'>
            </credential-horizontal>
          </div>
          </div>
          </ng-template>
          </p-carousel>
  </ng-container>
</div>
