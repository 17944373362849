import { Component, ViewChild, OnInit } from "@angular/core";
import { NgxScannerQrcodeComponent } from "ngx-scanner-qrcode";

@Component({
  selector: "app-scanner",
  templateUrl: "./scanner.component.html",
  styleUrls: ["./scanner.component.scss"],
})
export class ScannerComponent implements OnInit {
  @ViewChild("action", { static: true }) scanner: NgxScannerQrcodeComponent;

  ngOnInit(): void {
    // this.scanner.canvasStyles.strokeStyle = "transparent";
    // this.scanner.canvasStyles.fillStyle = "transparent";
  }

  cameraHandler(): void {
    if (this.scanner.isStart) {
      this.scanner.stop();
    } else {
      this.scanner.start();
    }
  }

  toggleCamera(): void {
    const listDevices = this.scanner.devices.value;
    const nextDeviceIndex =
      (this.scanner.deviceIndexActive + 1) % listDevices.length;
    const nextDeviceId = listDevices[nextDeviceIndex]?.deviceId;
    nextDeviceId && this.scanner.playDevice(nextDeviceId);
  }

  onDataChange(data: any): void {
    const elements = document.querySelector(".origin-overlay")?.children ?? [];

    for (let i = 0; i < elements.length; i++) {
      const canvas = elements[i] as HTMLCanvasElement | any;

      if (canvas.tagName === "CANVAS") {
        const { classList } = elements[i - 2] as HTMLElement;
        const { innerText, style } = elements[i - 1] as HTMLElement;

        if (!innerText || innerText.split(",").length !== 3) continue;

        const [_, isExpired] = JSON.parse(innerText);
        const color = isExpired ? "#700000bd" : "#167000bd";
        const classToAdd = isExpired ? "expired" : "non-expired";

        canvas.getContext("2d").fillStyle = color;
        classList.add(classToAdd);
        style.visibility = "hidden";
        canvas.getContext("2d").fill();
      }
    }
  }
}