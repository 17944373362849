<table mat-table [dataSource]="feesHistory" multiTemplateDataRows>
  <ng-container matColumnDef="fee_number">
    <th mat-header-cell *matHeaderCellDef> Concepto </th>
    <td mat-cell *matCellDef="let element"> {{(element['fee_number'] == 0) ? 'Matrícula' : (element['fee_number'] == -1) ?
      'Cuotas pendientes de revision' : 'Cuota ' + element['fee_number']}} </td>
  </ng-container>

  <ng-container matColumnDef="id_status">
    <th mat-header-cell *matHeaderCellDef> Estado </th>
    <td mat-cell *matCellDef="let element">
      <mat-chip-list>
        <mat-chip *ngIf="element['id_status'] == paymentStatus.COMPLETE" [style.backgroundColor]="'#8ac926'"
          class="button-state" selected>
          {{element['status_name']}}</mat-chip>
        <mat-chip *ngIf="element['id_status'] == paymentStatus.INCOMPLETE" [style.backgroundColor]="'#f6bd40'"
          class="button-state" selected>
          {{element['status_name']}}</mat-chip>
        <mat-chip *ngIf="element['id_status'] == paymentStatus.PENDING" [style.backgroundColor]="'#fd7a7e'" class="button-state"
          selected>
          {{element['status_name']}}</mat-chip>
      </mat-chip-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="total_amount">
    <th mat-header-cell *matHeaderCellDef> Monto abonado </th>
    <td mat-cell *matCellDef="let element"> {{(element['id_status'] == paymentStatus.PENDING) ? '-' :
      element['total_amount']}} </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <div class="example-element-description">
          <table mat-table [dataSource]="element.receipts">

            <ng-container matColumnDef="id_receipt">
              <th mat-header-cell *matHeaderCellDef> # </th>
              <td mat-cell *matCellDef="let element"> {{element.id_receipt}} </td>
            </ng-container>

            <ng-container matColumnDef="receipt_date">
              <th mat-header-cell *matHeaderCellDef> Fecha </th>
              <td mat-cell *matCellDef="let element"> {{element.receipt_date | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="status_name">
              <th mat-header-cell *matHeaderCellDef> Estado </th>
              <td mat-cell *matCellDef="let element"> {{element.status_name}} </td>
            </ng-container>

            <ng-container matColumnDef="receipt_amount">
              <th mat-header-cell *matHeaderCellDef> Monto </th>
              <td mat-cell *matCellDef="let element"> {{element.receipt_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="comments">
              <th mat-header-cell *matHeaderCellDef> Comentarios </th>
              <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
            </ng-container>

            <ng-container matColumnDef="answer">
              <th mat-header-cell *matHeaderCellDef> Respuesta </th>
              <td mat-cell *matCellDef="let element"> {{element.answer}} </td>
            </ng-container>

            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button *ngIf="element.image.split('.').pop() != 'pdf'" title="Ver comprobante" color="primary"
                  mat-flat-button (click)="showImageDialog(element.id_receipt, element.image)">
                  <mat-icon>receipt</mat-icon>
                </button>

                <a *ngIf="element.image.split('.').pop() == 'pdf'" title="Abrir PDF" [href]="element.image" target="_blank" color="primary" mat-flat-button>
                  <mat-icon>open_in_new</mat-icon>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="expandedColums"></tr>
            <tr mat-row *matRowDef="let row; columns: expandedColums;"></tr>
          </table>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
