<div class="container container-change-password">
  <div style="max-width: 90%; flex: 1 1 100%; box-sizing: border-box;">
    <h1>Modifique su contraseña</h1>
    <mat-card>
      <p>{{validationMsg}}</p>
      <form [formGroup]="forgotForm" novalidate (ngSubmit)="changePassword()">
        <div style="display: flex; flex-direction: row; box-sizing: border-box;" *ngIf="userValidated">
          <mat-form-field style="max-width: 100%; flex: 1 1 100%; box-sizing: border-box;" appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-error *ngIf="forgotForm.get('password')?.hasError('required')">
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
            <mat-error *ngIf="forgotForm.get('password')?.hasError('minlength')">
              La contraseña debe tener al menos 8 caracteres.
            </mat-error>
            <mat-error *ngIf="forgotForm.get('password')?.hasError('pattern')">
              Debe tener al menos una letra minúscula, una letra mayúscula y un número.
            </mat-error>
          </mat-form-field>
        </div>

        <div
          style="display: flex; flex-direction: row; box-sizing: border-box; place-content: center space-between; align-items: center; margin-top: 10px;">
          <button mat-flat-button color="link" type="button" (click)="backToLogin()">
            <mat-icon>chevron_left</mat-icon> Volver a Login
          </button>
          <button mat-raised-button style="max-width: 30%; min-width: min-content; flex: 1 1 100%; box-sizing: border-box;" color="primary" type="submit"
            *ngIf="userValidated">Enviar</button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
