import { Component } from '@angular/core';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'app-home',
  template: `
    <div class="container">
      <h1>¡Bienvenido a la Federación Argentina de Box, {{ username }}!</h1>
    </div>
  `,
  styles: [
    `
      .container {
        height: 100%;
        margin: 0 auto;
        padding: 2rem;
        text-align: center;
      }
      h1 {
        font-size: 2rem;
        margin-top: 5rem;
      }
      p {
        font-size: 1.2rem;
      }
    `
  ]
})
export class HomeComponent {
  username: string = '';

  constructor(private identityService: IdentityService) {
    const userLoged = this.identityService.getDecodedToken();
    if (userLoged.user_firstname) {
      this.username = userLoged.user_firstname[0].toUpperCase() + userLoged.user_firstname.substring(1);
    } else if (userLoged.role_name) {
      this.username = userLoged.role_name[0].toUpperCase() + userLoged.role_name.substring(1);
    }
  }
}