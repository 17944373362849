import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomTableColumnType } from "src/app/components/custom-table/enums/custom-table-column-type.enum";
import { CustomTableColumn } from "src/app/components/custom-table/interfaces/custom-table-column";
import { CustomTableStructure } from "src/app/components/custom-table/interfaces/custom-table-structure";
import { CustomTableGenericButton } from "src/app/components/custom-table/interfaces/custom-table-generic-button";
import { ApiCredentialTypeManagerService } from "src/app/services/api-credential-type-manager.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CredentialTypeManagerDialogComponent } from "src/app/components/credential-type-manager-dialog/credential-type-manager-dialog.component";
import { CredentialType } from "src/app/models/credential-type";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-credential-type-manager",
  templateUrl: "./credential-type-manager.component.html",
  styleUrls: ["./credential-type-manager.component.scss"],
})
export class CredentialTypeManagerComponent implements OnInit {

  constructor(
    private router: Router,
    private APICredentialTypeManager: ApiCredentialTypeManagerService,
    private changeRef: ChangeDetectorRef,
    private enrollmentDialog: MatDialog,
    private ui: UserInterfaceService,
    private utils: UtilsService
  ) {}

  credentialTypeList: CredentialType[];
  dataLoadedALL: boolean = false;
  detailsBtn: CustomTableGenericButton = {
    icon: "info",
    text: "Detalles",
    color: "primary",
  };

  credentialTypeColumns: CustomTableColumn[] = [
    { name: "code", text: "#", type: CustomTableColumnType.TEXT },
    { name: "name", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "enabled", text: "Activo", type: CustomTableColumnType.BOOLEAN_SEMAPHORE },
  ];
  credentialTypesTable: CustomTableStructure = { columns: this.credentialTypeColumns, data: [] };

  ngOnInit(): void {
    this.ui.spin$.next(true);
    this.getCredentialTypes();
  }

  getCredentialTypes() {
    this.APICredentialTypeManager.getAllCredentialTypes().subscribe(
      (response) => {
      this.credentialTypesTable.data = response.data.map((credentialType: CredentialType ) => {
        return {
          ...credentialType,
          enabled: this.utils.getValidEnabledBooleanSemaphore(credentialType.enabled),
        }

      }
      );
      this.credentialTypesTable = { columns: this.credentialTypeColumns, data: this.credentialTypesTable.data };
      this.changeRef.detectChanges();
      this.dataLoadedALL = true;
      this.ui.spin$.next(false);
    });
  }

  goToDetails(credentialType: CredentialType | { enabled: string }) {
    const credentialTypeData = {
      ...credentialType,
      enabled: credentialType.enabled === 'Si',
    };
    const dialogRef = this.enrollmentDialog.open(CredentialTypeManagerDialogComponent, {
      data: {
        title: "Detalles del tipo de credencial",
        credentialType: credentialTypeData,
      }
    });
    dialogRef.afterClosed().subscribe(finished => {
      if (finished === '') return;
      this.getCredentialTypes();
    });
  }

  new() {
    const dialogRef = this.enrollmentDialog.open(CredentialTypeManagerDialogComponent, {
      data: {
        title: "Nuevo tipo de credencial",
        credentialType: null,
      }
    });
    dialogRef.afterClosed().subscribe(finished => {
      if (finished === '') return;
      this.getCredentialTypes();
    });
  }
}
