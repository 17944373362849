
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <ng-container *ngIf="data.url && !data.url.endsWith('uploads/'); else noImage">
    <img src="{{data.url}}" width="500">
  </ng-container>
  <ng-template #noImage>
    <p>El comprobante no tiene una imagen asociada.</p>
  </ng-template>
</div>
<div mat-dialog-actions align="center">
  <button mat-flat-button *ngIf="data.printable" color="primary" (click)="print()" ><mat-icon>print</mat-icon> Imprimir</button>
  <button mat-flat-button mat-dialog-close>Cerrar</button>
</div>
