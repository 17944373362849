  <div class="container container-licenselist-header">
    <div style="max-height: 10%; flex: 1 1 100%; box-sizing: border-box; margin-bottom: 20px;">
      <section-header 
        title="Licencias"
        [showNewButton]="true"
        [newButtonText]="'Nueva Persona'"
        (newButtonPressed)="new()">
      </section-header>
    </div>
  
    <div class="licenses-table-container">


    <mat-card *ngIf="licenseTypeList.length">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Legajos">
            <license-list-table
              [licenseType]="'all'"

            ></license-list-table>
          </mat-tab>
          <div *ngFor="let licenseType of licenseTypeList">
            <mat-tab label="{{ licenseType.name }}">
              <license-list-table
                [licenseType]="licenseType.code"
              ></license-list-table>
            </mat-tab>
          </div>
        </mat-tab-group>
    </mat-card>
    </div>
</div>
