export class StudentCourse {
  id: number;
  name: string;
  start_date: Date;
  end_date: Date;
  fees: number;
  enrollment_price: number;
  fee_price: number;
  total_price: number;
  description: string;
  day_time: string;
  enrollment_date: Date;
  id_enrollment_status: number;
  enrollment_status: string;
  payment_status_fee: number;
  payment_status_fee_name: string;
  last_payment_fee: number;
  last_payment_date: Date;
  person_name: string;
}
