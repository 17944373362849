export class Course {
  id: number;
  name: string;
  start_date: Date;
  end_date: Date;
  fees: number;
  enrollment_price: number;
  fee_price: number;
  total_price: number;
  description: string;
  day_time: string;
  vacancies: number;
  enrollments?: number;
  enrollment_enabled: string;
}
