<custom-table
  [filterable]="true"
  [pageIndex]="tablePageIndex"
  [paginatorPageSize]="tablePageSize"
  id="receiptsByStatusTable"
  *ngIf="dataLoaded"
  [dataSource]="tableData"
  [showActions]="true"
  [showGenericButton1]="showReverseButton"
  [genericButton1]="reverseBtn"
  [genericButton1Title]="reverseBtn.title!"
  (genericButton1Pressed)="reverseReceiptStatus($event)"
  [showGenericButton2]="showReceiptButton"
  [genericButton2]="receiptBtn"
  [genericButton2Title]="receiptBtn.title!"
  (genericButton2Pressed)="showReceipt($event)"
  [showDetailsButton]="showDetailsButton"
  (detailsButtonPressed)="goToReceiptDetails($event)"
  (pageChange)="onPageChange($event)"
>
</custom-table>
