import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-license-form',
  templateUrl: './license-form.component.html',
  styleUrls: ['./license-form.component.scss']
})
export class LicenseFormComponent implements OnInit {

  title: string = "Datos de la persona";

  personId: number = 0;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (params['personId']) {
        this.personId = params['personId'];
      }
    });
  }

  ngOnInit(): void {
  }

}
