<div class="container container-courselist">
  <div style="display: flex; flex-direction: row; box-sizing: border-box; margin-bottom: 20px;">
    <section-header title="{{title}}"></section-header>
  </div>

  <div style="display: flex; flex-direction: row; box-sizing: border-box; margin-bottom: 20px;">
    <mat-card style="max-width: 100%; flex: 1 1 100%; box-sizing: border-box; margin-right: 20px;">
      <mat-card-subtitle>Información del comprobante</mat-card-subtitle>
      <mat-card-content>
        <div style="display: flex; flex-direction: column; box-sizing: border-box;">
          <div style="display: flex; flex-direction: row; box-sizing: border-box; margin-bottom: 20px;">
            <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
              <strong>Usuario:</strong><br> {{receipt.person_name}}

              <button mat-flat-button title="Ver información del usuario" (click)="showUserInformation()"><mat-icon>zoom_in</mat-icon></button>
            </div>
            <div style="flex: 1 1 1e-9px; box-sizing: border-box;"><strong>Curso:</strong><br> {{receipt.course_name}}</div>
            <div style="flex: 1 1 1e-9px; box-sizing: border-box;"><strong>Comprobante subido el:</strong><br> {{receipt.date | date: 'dd/MM/yyy'}}
            </div>
            <div style="flex: 1 1 1e-9px; box-sizing: border-box;"><strong>Fecha del pago:</strong><br>
              {{(receipt.transfer_date | date: 'dd/MM/yyy') || ' - '}}
            </div>
            <div style="flex: 1 1 1e-9px; box-sizing: border-box;"><strong>Nombre del titular de la cta. Bancaria:</strong><br>
              {{receipt.transfer_owner || ' - '}}
            </div>
          </div>
          <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
            <span><strong>Observaciones:</strong><br>{{receipt.comments || ' - '}}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="receipt.image" style="max-width: 20%; flex: 1 1 100%; box-sizing: border-box;">
      <div *ngIf="receipt.image.split('.').pop() != 'pdf'">
        <img src="{{receipt.image}}" class="img-receipt">

        <button color="primary" mat-flat-button class="btn-pdf-receipt"
          (click)="showImageDialog(receipt.image)">
          <mat-icon>search</mat-icon> Ampliar
        </button>
      </div>

      <div *ngIf="receipt.image.split('.').pop() == 'pdf'" class="btn-pdf-receipt-container">
        <a [href]="receipt.image" target="_blank" color="primary" mat-flat-button class="btn-pdf-receipt">
          <mat-icon>open_in_new</mat-icon> Abrir PDF
        </a>
      </div>
    </mat-card>
  </div>

  <div style="display: flex; flex-direction: row; box-sizing: border-box;">
    <div style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box; margin-right: 20px;">
      <mat-card>
        <mat-card-subtitle>Validación de comprobante</mat-card-subtitle>
        <mat-card-content>
          <form [formGroup]="receiptDetailsForm" (ngSubmit)="sendAnswerReceipt()">
            <div style="display: flex; flex-direction: column; box-sizing: border-box;">

              <div style="flex: 1 1 100%; box-sizing: border-box;">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Estado</mat-label>
                  <mat-select formControlName="status" (valueChange)="onChangeStatus($event)">
                    <mat-option *ngFor="let sv of statusValues" value="{{sv.id}}">
                      {{sv.name}}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="receiptDetailsForm.get('status')?.hasError('required')">
                    Este campo es <strong>obligatorio.</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <payment-fees [hidden]="!showPaymentFees" [form]="receiptDetailsForm" [courseId]="courseId" [personId]="personId"
                [selectedFees]="selectedFees" [enrollmentPrice]="enrollmentPrice" [feePrice]="feePrice"></payment-fees>

              <span class="form-custom-error"
                *ngIf="receiptDetailsForm.get('fees')?.hasError('required') && (receiptDetailsForm.get('fees')?.dirty || receiptDetailsForm.get('fees')?.touched)">
                Este campo es obligatorio.
              </span>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Observaciones</mat-label>
                <textarea matInput formControlName="comments" rows="5"></textarea>

                <mat-error *ngIf="receiptDetailsForm.get('comments')?.hasError('required')">
                  Este campo es <strong>obligatorio.</strong>
                </mat-error>
              </mat-form-field>


              <div
                style="display: flex; flex-direction: row; box-sizing: border-box; place-content: stretch space-around; align-items: stretch;">
                <button style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box;" mat-flat-button color="primary" type="submit"
                  class="button-primary">Enviar</button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
    <div style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box; overflow: hidden;">
      <mat-card>
        <mat-card-subtitle>Historial de Pagos</mat-card-subtitle>
        <mat-card-content>
          <fees-history-table *ngIf="loadFeesHistory" [courseId]="courseId" [personId]="personId"></fees-history-table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
