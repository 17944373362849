export class Credential {
  enabled: string | boolean;
  id: number;
  id_person: number;
  firstname?: string;
  lastname?: string;
  gender?: string;
  identity_number?: string;
  photo?: string;
  status?: string ;
  type: string;
  typeName?: string;
  valid_until: string;
  updated_at: string;
  valid?: boolean;
}

export class CredentialTypeItem {
  code: string;
  name: string;
}

export class CredentialListTable {
  [key: string]: CredentialTypeItem;
}
