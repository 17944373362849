import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CredentialTypeItem, CredentialListTable } from "src/app/models/credential";
import { Person } from "src/app/models/person";
import { ApiCredentialsService } from "src/app/services/api-credentials.service";

@Component({
  selector: "credentials-list",
  templateUrl: "./credentials-list.component.html",
  styleUrls: ["./credentials-list.component.scss"],
})
export class CredentialsListComponent implements OnInit {
  personsList: Person[] = [];
  credentialTypeList: CredentialTypeItem[] = [];
  credentialList: CredentialListTable = {};
  today: Date = new Date();

  constructor(
    private APICredentials: ApiCredentialsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCredentialTypes();
  }

  new() {
    console.log("new person for credential");
    this.router.navigate(['credential-form']);
  }

  goToEdit(id: number) {
    this.router.navigate(["credential-form", id]);
  }

  getCredentialTypes() {
    this.APICredentials.getTypes().subscribe((response) => {
      this.credentialTypeList = response.data.sort((a: {name: string}, b: {name: string}) => a.name.localeCompare(b.name)).reverse();
      console.log("Tipos de credenciales: ",response.data);
    });
    return this.credentialTypeList;
  }
}
