<div class="container">
    <h1>
        TEST COMPONENT 
    </h1>
    <custom-chart-component
        [chartType]="chartType"
        [chartData]="chartData"
        [chartTitle]="chartTitle"
        [chartLabels]="chartLabels"
    />
</div>
  

