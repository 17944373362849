<p-dialog header="Flujo de solicitud" [modal]="true" [(visible)]="visible" [style]="{ maxWidth: '100%' }"
  (onHide)="closeDialog()">

  <p-organizationChart [value]="flowRequest">
    <ng-template let-node pTemplate="default">
      <div class="p-2 text-center">
        <span style="font-size: 1.2rem;" [ngClass]="node.icon"></span>
        <div class="p-2">
          {{ node.label }}
        </div>
      </div>
    </ng-template>
  </p-organizationChart>


</p-dialog>