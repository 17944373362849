import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Course } from 'src/app/models/course';
import { EnrollmentStatus } from 'src/app/models/enrollment-status';
import { ApiCoursesService } from 'src/app/services/api-courses.service';
import { ApiEnrollmentStatusService } from 'src/app/services/api-enrollment-status.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EnrollmentDialog } from 'src/app/models/enrollment-dialog';
import { ApiStudentsService } from 'src/app/services/api-students.service';
import { EnrollRequest } from 'src/app/models/enroll-requests';
import { CourseListItem } from 'src/app/models/course-list-item';
import { UserInterfaceService } from 'src/app/services/user-interface.service';
import { EnrollmentStatusEnum } from 'src/app/enums/enrollment-status-enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-courses-list-dialog',
  templateUrl: './courses-list-dialog.component.html',
  styleUrls: ['./courses-list-dialog.component.scss']
})
export class CoursesListDialogComponent implements OnInit {

  courses: Course[] = [];

  courseId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, personId: number },
    private APICourses: ApiCoursesService,
    private ui: UserInterfaceService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.getCourses();
  }

  getCourses() {
    this.ui.spin$.next(true);
    this.APICourses.getAll().subscribe(response => {
      this.courses = response.data;
      this.ui.spin$.next(false);
    });
  }

  onCourseChange(courseId: number) {
    this.courseId = courseId;
  }

  goToAddPayment() {
    this.router.navigate(['add-payment/' + this.courseId + '/' + this.data.personId])
  }

}
