<div class="container container-courselist">
  <div
    style="max-height: 10%; flex: 1 1 100%; box-sizing: border-box; margin-bottom: 20px; flex-direction: row; display: flex; place-content: center space-between; align-items: center;">
    <section-header title="Comprobantes" [showNewButton]="false"></section-header>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
      <mat-tab label="Pendientes">
        <mat-card>
          <mat-card-content>
            <receipts-by-status-table #pendingReceipts (refreshData)="refreshTables()"></receipts-by-status-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Aprobados">
        <mat-card>
          <mat-card-content>
            <receipts-by-status-table #approvedReceipts [status]="receiptStatusValues.APPROVED" (refreshData)="refreshTables()"></receipts-by-status-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Rechazados">
        <mat-card>
          <mat-card-content>
            <receipts-by-status-table #rejectedReceipts [status]="receiptStatusValues.REJECTED" (refreshData)="refreshTables()"></receipts-by-status-table>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
