import { Injectable } from "@angular/core";
import { Record } from "../models/record";
import { ApiBaseService } from "./api-base.service";
import { HttpClient } from "@angular/common/http";
import { HttpBaseResponse } from "../models/base-response";

@Injectable({
  providedIn: "root",
})
export class ApiRecordsService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "records";
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param recordId number
   * @methodOf ApiRecordsService
   * @description Se obtiene la información de los records.
   * @return HttpBaseResponse
   */

  getAll(recordId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (recordId) endpoint += `/${recordId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name getTypes
   * @methodOf ApiRecordsService
   * @description Se obtiene la información de los tipos de records.
   * @return HttpBaseResponse
   */

  getTypes() {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/types`
    );
  }

  getAllWithPersonData(recordType: string, personId?: number) {
    if (recordType === "all") {
      return this.http.get<HttpBaseResponse>(
        `${this._apiUrl}/${this._controller}/with_person_data/all`
      );
    }
    let endpoint = `${this._apiUrl}/${this._controller}/with_person_data/${recordType}`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getPersonIdByRecordId(recordId: number) {
    return this.http.get<HttpBaseResponse>(`${this._apiUrl}/${this._controller}/get_person_id_by_record_id/${recordId}`);
  }

  update(recordData: Record) {
    return this.http.patch<any>(`${this._apiUrl}/${this._controller}/form`, recordData);
  }

  create(recordData: Record) {
    console.log("recordData", recordData);
    return this.http.post<any>(`${this._apiUrl}/${this._controller}/form`, recordData);
  }

}
