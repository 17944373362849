<div class="container" *ngIf="dataLoaded">
  <div class="row flex-column flex-fill gap-20">
    <section-header title="{{title}}: {{course.name}}"></section-header>
  </div>

  <mat-card>
    <mat-card-subtitle>Información del curso</mat-card-subtitle>
    <mat-card-content>
      <div class="container-course-info">
        <div class="row wrap">
          <div class="flex-fill">
            <strong>Fecha de inicio:</strong><br>
            {{course.start_date | date: 'dd/MM/yyyy'}}
          </div>
          <div class="flex-fill">
            <strong>Fecha de Fin:</strong><br>
            {{course.end_date | date: 'dd/MM/yyyy'}}
          </div>
          <div class="flex-fill">
            <strong>Días y Horarios:</strong><br>
            {{course.day_time}}
          </div>
          <div class="flex-fill">
            <strong>Estado de inscripción:</strong><br>
            {{course.enrollment_status}}
          </div>
          <div class="flex-fill">
            <strong>Fecha de inscripción:</strong><br>
            {{course.enrollment_date | date: 'dd/MM/yyyy'}}
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; flex-direction: row;">
          <div style="flex: 1;">
            <strong>Precio Matrícula:</strong><br>
            {{course.enrollment_price}}
          </div>
          <div style="flex: 1;">
            <strong>Cantidad de Cuotas:</strong><br>
            {{course.fees}}
          </div>
          <div style="flex: 1; flex-basis: 40%;">
            <strong>Precio por cuota:</strong><br>
            {{course.fee_price}}
          </div>
        </div>

        <div style="display: flex; flex-wrap: wrap; flex-direction: row;">
          <div style="flex: 1 1 1e-9px; box-sizing: border-box;">
            <strong>Descripción del curso:</strong><br>
            {{course.description}}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="row wrap" style="margin: 20px; flex-wrap: wrap;">
    <div style="flex: 1; display: flex; align-items: center; justify-content: space-between;">
      <div>
        <span>Historial de Pagos</span>
      </div>
      <button style="display: flex; justify-content: center;" mat-flat-button color="primary" *ngIf="showAddReceiptButton"
        (click)="goToAddPayment()">
        <mat-icon>add</mat-icon> Agregar comprobante
      </button>
    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <fees-history-table [courseId]="courseId" [personId]="personId" (hasReceiptsToApprove)="setCanAddReceipt($event)"></fees-history-table>
    </mat-card-content>
  </mat-card>
</div>
