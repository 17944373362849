import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { Router, RouterState, NavigationEnd } from "@angular/router";
import { ApiCredentialsService } from "src/app/services/api-credentials.service";
import { UtilsService } from "src/app/services/utils.service";
import { IdentityService } from "src/app/services/identity.service";
import { UserProfilesEnum } from "src/app/enums/user-profiles-enum";

@Component({
  selector: "navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isLoggedIn$: Observable<boolean>;
  useLegajo$: Observable<boolean>;
  legajoId$: Observable<number | string | undefined>;
  useCredential$: Observable<boolean>;
  useLicense$: Observable<boolean>;
  isScanner: boolean;

  loggedUser$: Observable<any>;
  loggedUserName: string = "Default User";

  userProfile: number | undefined;
  userProfiles: typeof UserProfilesEnum = UserProfilesEnum;
  AllUserProfiles: number[] = [];

  showNav = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private utils: UtilsService,
    private APICredentials: ApiCredentialsService,
    public identity: IdentityService
  ) {
    const state: RouterState = router.routerState;
    this.loggedUser$ = this.identity.getLoggedUser;
    this.isScanner =
      state.snapshot.url === "/scanner" ||
      window.location.pathname === "/scanner";
  }

  ngOnInit(): void {
    this.isLoggedIn$ = this.identity.isLoggedIn;
    this.useLegajo$ = this.identity.getUseLegajo;
    this.legajoId$ = this.identity.getLegajoId;
    this.useCredential$ = this.identity.getUseCredential;
    this.useLicense$ = this.identity.getUseLicense;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showNav = !event.urlAfterRedirects.includes("user-credential");
      }
    });
    this.checkCookies();
    this.identity.getLoggedUser.subscribe(
      (loggedUser: any) => {
        if (!loggedUser || loggedUser === "invalid") return;
        this.loggedUserName = this.identity.getLoggedUserName();
        this.userProfile = loggedUser.id_profile;
        this.AllUserProfiles = [loggedUser.id_profile, ...loggedUser.extra_profiles] as number[]
      }
    );
  }

  showMenuByProfile(profile_ids: number[]): boolean {
    return profile_ids.some(profile => this.AllUserProfiles.includes(profile));
  }

  checkCookies() {
    const expirationDate = localStorage.getItem("expired");
    const token = localStorage.getItem("token");
    const todayDate = new Date();

    if (!expirationDate || !token) {
      console.log("Cache not set or token missing. Redirecting to login.");
      todayDate.setHours(todayDate.getHours() + 2);
      localStorage.setItem("expired", todayDate.toISOString());
      this.router.navigate(["/login"]);
      return;
    }


    const expiryDate = new Date(expirationDate);

    if (todayDate > expiryDate) {
      console.log("Token expired. Redirecting to login.");
      this.identity.logout();
      return;
    }

    try {
      // Assuming you have a method to validate the token
      this.identity.validateToken(token);
    } catch (error) {
      console.error("Invalid token specified", error);
      this.router.navigate(["/login"]);
    }
  }

  goHome() {
    this.router.navigate(["/"]);
  }

  logout() {
    if (confirm("¿Desea cerrar sesión?")) {
      this.identity.logout();
    }
  }
}
