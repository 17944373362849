<h2 *ngIf="!creatingNewLicense" mat-dialog-title>"{{ data.title }}"</h2>

<div style="position: relative">
</div>
<div class="card-content" mat-dialog-content [formGroup]="licenseForm">
  <div class="card-content" mat-dialog-content>
    <div class="user-license" *ngIf="personData && license !== undefined">
      <license-horizontal [license]="license" [person]="personData">
      </license-horizontal>
      LICENCIA HORIZONTAL
    </div>
    <div
      *ngIf="!creatingNewLicense && !hasNoLicenses"
      class="licenses-paginator-buttons"
    >
      <button
        [disabled]="licenseIndex == 0"
        mat-flat-button
        color="primary"
        (click)="getPreviousLicense()"
      >
        Anterior
      </button>
      <div>{{ licenseIndex + 1 }}/{{ licenses.length }}</div>
      <button
        [disabled]="licenseIndex == licenses.length - 1"
        mat-flat-button
        color="primary"
        (click)="getNextLicense()"
      >
        Siguiente
      </button>
    </div>

    <div class="form-control">
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Estado</mat-label>
        <mat-select formControlName="enabled">
          <mat-option [value]="false">Inhabilitada</mat-option>
          <mat-option [value]="true">Habilitada</mat-option>
        </mat-select>
        <mat-error *ngIf="licenseForm.get('enabled')?.hasError('required')">
          Este campo es <strong>obligatorio.</strong>
        </mat-error>
      </mat-form-field>
      <!-- <form >
        <p-floatLabel>Estado</p-floatLabel>
        <p-dropdown formControlName="enabled">
          <p-option [value]="false">Inhabilitada</p-option>
          <p-option [value]="true">Habilitada</p-option>
        </p-dropdown>
        
    </form> -->

      <mat-form-field appearance="outline" class="form-field">
        <mat-label>Tipo de licencia</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let kind of licenseTypes" [value]="kind.code">{{
            kind.name
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="licenseForm.get('type')?.hasError('required')">
          Este campo es <strong>obligatorio.</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div
      class="form-control"
      *ngIf="licenseForm.get('enabled')?.value === true || creatingNewLicense"
    >
      <div class="group-gap" *ngIf="!validUntilNotSetted">
        <h5 class="mb-3">¿Desea modificar la fecha de vencimiento?</h5>
        <div class="d-block my-3">
          <div class="custom-control custom-radio">
            <input
              id="yes"
              type="radio"
              class="custom-control-input"
              value="yes"
              [checked]="extendExpiration"
              (change)="changeExpiration($event)"
            />
            <label
              class="custom-control-label"
              style="font-size: 17px"
              for="yes"
              >SI</label
            >
          </div>

          <div class="custom-control custom-radio">
            <input
              id="no"
              type="radio"
              class="custom-control-input"
              value="no"
              [checked]="!extendExpiration"
              (change)="changeExpiration($event)"
            />
            <label class="custom-control-label" style="font-size: 17px" for="no"
              >NO</label
            >
          </div>
        </div>
      </div>

      <mat-form-field
        appearance="outline"
        class="form-field"
        style="margin: 20px 0px 0px 20px"
        *ngIf="
          creatingNewLicense ||
          (licenseForm.get('enabled')?.value === true &&
            (extendExpiration || validUntilNotSetted))
        "
      >
        <mat-label>Nueva fecha de vencimiento</mat-label>
        <input matInput type="date" formControlName="valid_until" />
        <!--<mat-error *ngIf="regForm.get('birthdate')?.hasError('required')">
            Este campo es <strong>obligatorio.</strong>
           </mat-error> -->
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end" style="margin-bottom: 0">
    <button mat-flat-button mat-dialog-close (click)="cerrar()">Cerrar</button>
    <button mat-flat-button color="primary" (click)="save()">Guardar</button>
  </div>
</div>
