<div class="container container-payment">
  <div style="max-width: 70%; flex: 1 1 100%; box-sizing: border-box;">
    <h1>Medios de Pago</h1>
    <mat-card>
        <mat-card-content>
          <p>Podés abonar la matrícula de inscripción o las cuotas de tus cursos haciendo una transferencia a la siguiente cuenta:</p>
          <ul>
            <li>Banco Galicia</li>
            <li>Suc. 7 - Boedo</li>
            <li>Cta. Cte. Nro.: 12299/9 007/1</li>
            <li>CUIT Nro.: 33-52806953-9</li>
            <li>CBU: 0070007820000012299913</li>
          </ul>

          <p>Una vez realizada la transferencia, deberás informar tu pago en la sección de <strong>Agregar comprobante</strong> que se encuentra dentro del detalle de tus cursos.</p>
        </mat-card-content>
    </mat-card>
  </div>
</div>
