import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiUsersService } from 'src/app/services/api-users.service';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.scss']
})
export class UserValidationComponent implements OnInit {

  iconMsg: string = "";
  validationMsg: string = "";

  constructor(private router: Router,
    private route: ActivatedRoute,
    private APIUsers: ApiUsersService) {
    this.route.params.subscribe(params => {
      if (!params['token']) {
        this.router.navigate(['login']);
      }
      else {
        this.APIUsers.validateUserByToken(params['token']).subscribe(response => {
          if (response.status == 200) {
            this.iconMsg = "check_circle";
            this.validationMsg = "El usuario se activó correctamente.";
          }
        },
          err => {
            this.iconMsg = "cancel";
            this.validationMsg = "El usuario que se intenta validar no existe.";
          });
      }
    });
  }

  ngOnInit(): void {
  }

  backToLogin() {
    this.router.navigate(['login']);
  }

}
