<form [formGroup]="enrollmentForm" (ngSubmit)="enroll()">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <div mat-dialog-content>
    <h4>Alumno: {{data.studentName}}</h4>
    <div style="display: flex; flex-direction: column; box-sizing: border-box;">
      <mat-form-field appearance="outline">
        <mat-label>Cursos</mat-label>
        <mat-select formControlName="id_course" (selectionChange)="onCourseChange()">
          <mat-option *ngFor="let c of courses" [value]="c.id">({{c.id}}) {{c.name}} ({{c.enrollments}}/{{c.vacancies}})
          </mat-option>
        </mat-select>
        <mat-error *ngIf="enrollmentForm.get('id_course')?.hasError('required')">
          Este campo es <strong>obligatorio.</strong>
        </mat-error>
      </mat-form-field>

      <span *ngIf="courseIsFull" class="form-custom-error">El curso está completo.</span>

      <mat-form-field appearance="outline">
        <mat-label>Estado de Inscripción</mat-label>
        <mat-select formControlName="id_enrollment_status">
          <mat-option *ngFor="let es of enrollmentStatus" [value]="es.id">{{es.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="enrollmentForm.get('id_enrollment_status')?.hasError('required')">
          Este campo es <strong>obligatorio.</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cerrar</button>
    <button mat-flat-button [mat-dialog-close]="enrollFinished"
      [disabled]="!enrollmentForm.valid || (courseIsFull && enrollmentForm.get('id_enrollment_status')!.value != enrollmentStatusValues.REJECTED)"
      type="submit" color="primary">Asignar</button>
  </div>
</form>
