<div class="container container-forgot-password">
  <div class="flex-box">
    <h1>Olvidé mi contraseña</h1>
    <mat-card>
      <p>Ingrese el correo electrónico de su usuario. <br>Enviaremos un email a su casilla con las instrucciones para cambiar su contraseña.</p>
      <form [formGroup]="forgotForm" novalidate (ngSubmit)="sendValidationEmail()">
        <div style="display: flex; flex-direction: row; box-sizing: border-box;">
          <mat-form-field style="max-width: 100%; flex: 1 1 100%; box-sizing: border-box;" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email">
            <mat-error *ngIf="forgotForm.get('email')?.hasError('required')">
              Este campo es <strong>obligatorio.</strong>
            </mat-error>
            <mat-error *ngIf="forgotForm.get('email')?.hasError('pattern')">
              Ingrese una casilla de email válida.
            </mat-error>
          </mat-form-field>
        </div>

        <div
          style="display: flex; flex-direction: row; box-sizing: border-box; align-items: center; place-content: center space-between;">
          <button mat-flat-button color="link" type="button" (click)="backToLogin()">
            <mat-icon>chevron_left</mat-icon> Volver a Login
          </button>
          <button mat-raised-button style="max-width: 30%; min-width: min-content; flex: 1 1 100%; box-sizing: border-box;" color="primary"
            type="submit">Enviar</button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
