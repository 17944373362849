export class FeesPaymentRequest {
  number: number;
  amount?: number;
  id_payment_receipt?: number;
  delete?: boolean;
}

export class AddPaymentRequest {
  id_course: number;
  id_person: number;
  image: string;
  fees: FeesPaymentRequest[];
  transfer_date: Date;
  transfer_owner: string;
  comments: string;
}
