<div [formGroup]="form">
  <div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Concepto de Pago</mat-label>
        <mat-select multiple (valueChange)="onFeesChange($event)" [value]="selectedFees">
          <mat-option *ngFor="let pf of pendingFees" value="{{pf.fee_number}}" [disabled]="isFeeDisabled(pf)">
            {{created? getFeesLabel(pf) : getFeesFieldLabels(pf)}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('fees')?.hasError('required')">
          Este campo es <strong>obligatorio.</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="profileId == userProfilesValues.ADMIN || userProfilesValues.TREASURY">
      <ng-container *ngFor="let af of fees">
        <mat-form-field *ngIf="af.number != -1" appearance="outline" style="margin-right: 10px;">
          <mat-label>
            Monto {{(af.number == 0) ? 
              'Matrícula ' + getFeeMaxPrice(af) :
              'Cuota ' + af.number + " " + getFeeMaxPrice(af)}}
          </mat-label> 
          <input matInput type="text" value="{{af.amount}}" [max]="(af.number == 0) ? enrollmentPrice : feePrice" (change)="onAmountChange(af.number, $event)">
        </mat-form-field>
    </ng-container>
    </div>
  </div>
</div>
