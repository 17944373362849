import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient } from '@angular/common/http';
import { HttpBaseResponse } from '../models/base-response';

@Injectable({
  providedIn: 'root'
})
export class ApiCitiesService extends ApiBaseService {

  constructor(private http: HttpClient) {
    super();
    this._controller = "cities";
  }

  /**
    * @ngdoc function
    * @name getAll
    * @param cityId number
    * @methodOf ApiCitiesService
    * @description Se obtiene la información de las localidades.
    * @return HttpBaseResponse
    */

  getAll(cityId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (cityId) endpoint += `/${cityId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }


}
