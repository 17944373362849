<div class="container">
    <div class="container-scanner">
      <div class="container-video">
        <ngx-scanner-qrcode #action="scanner"></ngx-scanner-qrcode>
        <div class="controls">
          <button class="controls__button" (click)="cameraHandler()">
            <mat-icon>camera_alt</mat-icon>
          </button>
          <button class="controls__button" (click)="toggleCamera()">
            <mat-icon>switch_camera</mat-icon>
          </button>
        </div>
      </div>

      <div class="container-data">
        <!-- Loading -->
        <p *ngIf="action.isLoading">⌛ Loading...</p>

        <div class='user-credential'>
          <!-- <credential>
          </credential> -->
        </div>
        
      </div>
    </div>

    <!-- data  -->
    <span>{{ onDataChange(action.data.value) }}</span>
  </div>