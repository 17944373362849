<custom-table
  *ngIf="dataLoaded"
  [dataSource]="tablePendingEnrollments"
  [showActions]="true"
  [showGenericButton1]="true"
  [genericButton1]="approveEnrollBtn"
  genericButton1Title="Validar"
  (genericButton1Pressed)="showEnrollDialog($event)"
>
</custom-table>
